import { useSnackbar } from '@leanlancer/common'
import { FacebookSignInButton, GoogleSignInButton } from '@leanlancer/ui'
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import validate from 'validate.js'

const functions = firebase.app().functions('europe-west1')
const sendPasswordResetTemplate = functions.httpsCallable('auth-sendPasswordReset')

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.secondary.dark,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginBottom: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  forgotPasswordLink: {
    cursor: 'pointer'
  }
}))

const SignIn = ({ ...props }) => {
  const history = useHistory()
  const { t } = useTranslation('freelancer')
  const snackbar = useSnackbar()

  const classes = useStyles()
  const [signInError, setSignInError] = useState(null)
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleBack = () => {
    history.goBack()
  }

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handlePasswordReset = () => {
    if (formState.errors.email) {
      return setFormState({
        ...formState,
        touched: {
          email: true
        },
        errors: {
          email: [t('signIn.passwordResetEmailMissing')]
        }
      })
    }

    sendPasswordResetTemplate({
      redirectUrl: window.location.href
    }).then(() => {
      snackbar({ type: 'open', message: t('signIn.passwordResetSent') })
    }).catch((err) => {
      snackbar({ type: 'open', message: err.message, severity: 'error' })
    })
  }

  const handleSignIn = async (event) => {
    event.preventDefault()
    const { email, password } = formState.values
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      history.push('/')
    } catch (error) {
      console.error(error)
      setSignInError(error.message)
    }
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'
              >
                {t('signIn.quote')}
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant='body1'
                >
                  - {t('signIn.quoteAuthor')}
                </Typography>
                <Typography
                  className={classes.bio}
                  variant='body2'
                >
                  {t('signIn.quoteProfession')}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignIn}
              >
                <Typography
                  className={classes.title}
                  variant='h2'
                  gutterBottom
                >
                  {t('signIn.title')}
                </Typography>
                <Typography
                  color='textSecondary'
                  gutterBottom
                >
                  {t('signIn.subline')}
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <FacebookSignInButton
                      onSignIn={() => history.push('/')}
                      onError={(error) => setSignInError(error.message)}
                      label={t('signIn.loginFacebook')}
                    />
                  </Grid>
                  <Grid item>
                    <GoogleSignInButton
                      onSignIn={() => history.push('/')}
                      onError={(error) => setSignInError(error.message)}
                      label={t('signIn.loginGoogle')}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className={classes.sugestion}
                  color='textSecondary'
                  variant='body1'
                >
                  {t('signIn.loginEmail')}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={t('signIn.emailLabel')}
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label={t('signIn.passwordLabel')}
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Typography
                  color='textSecondary'
                  variant='body1'
                  className={classes.forgotPasswordLink}
                >
                  <Link
                    onClick={handlePasswordReset}
                    variant='h6'
                  >
                    {t('signIn.forgotPassword')}
                  </Link>
                </Typography>

                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  {t('signIn.submit')}
                </Button>
                {signInError && (
                  <FormHelperText error>
                    {signInError}
                  </FormHelperText>
                )}
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  {t('signIn.noAccount')}{' '}
                  <Link
                    component={RouterLink}
                    to='/sign-up'
                    variant='h6'
                  >
                    {t('signIn.signUp')}
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignIn.propTypes = {
}

export default SignIn
