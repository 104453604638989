import {
  Button,
  Card,

  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { filter, lowerCase, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {},
  chip: {
    margin: theme.spacing(0, 1)
  },
  addButton: {
    height: '100%'
  }
}))

const ProfileSkills = ({ className, values = [], onChange, ...rest }) => {
  const classes = useStyles()
  const [newSkill, setNewSkill] = useState('')
  const [newSkillError, setNewSkillError] = useState()
  const { t } = useTranslation('freelancer')

  const handleAddSkill = event => {
    event.preventDefault()
    if (newSkill) {
      if (map(values, 'id').includes(lowerCase(newSkill))) {
        return setNewSkillError('Skill is already in your list')
      }
      const newVal = [
        ...values,
        { label: newSkill, id: lowerCase(newSkill) }
      ]
      onChange(newVal)
      setNewSkill('')
    }
  }

  const handleDeleteSkill = (skill) => {
    const newVal = filter(values, ({ id }) => id !== skill.id)
    onChange(newVal)
  }

  const handleChangeNewSkill = (value) => {
    setNewSkill(value)
    setNewSkillError('')
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
      >
        <CardHeader
          title={t('profile.skills.title')}
          subheader={t('profile.skills.subheader')}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={9}
              sm={10}
              md={11}
            >
              <TextField
                fullWidth
                variant='outlined'
                placeholder={t('profile.skills.placeholder')}
                onChange={(e) => handleChangeNewSkill(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddSkill(e)
                  }
                }}
                value={newSkill}
                error={!!newSkillError}
                helperText={newSkillError}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
            >
              <Button
                aria-label='add'
                className={classes.addButton}
                variant='outlined'
                color='primary'
                onClick={handleAddSkill}
              >
                <AddIcon />
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Grid
                direction='row'
                alignItems='center'
                container
              >
                {values.map((data) => {
                  return (
                    <Chip
                      key={data.id}
                      label={data.label}
                      className={classes.chip}
                      onDelete={() => handleDeleteSkill(data)}
                    />
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  )
}

ProfileSkills.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired
}

export default ProfileSkills
