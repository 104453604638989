import React, { useContext, useEffect, useState } from 'react'
import { Layouts, SidebarMenuItem } from '@leanlancer/ui'
import routes from '../constants/routes'
import { map, compact } from 'lodash'

import { NavLink, useRouteMatch, useHistory } from 'react-router-dom'
import AuthUserContext from '../contexts/authUser.context'
import { useProfilePic } from '@leanlancer/common'
import LanguageContext from '../contexts/langauge.context'

const Item = ({ title, href, icon }) => {
  const match = useRouteMatch(href)

  let active
  if (href === '/') {
    active = match?.isExact
  } else {
    active = match
  }

  return (
    <NavLink to={href}>
      <SidebarMenuItem title={title} icon={icon} active={active} />
    </NavLink>
  )
}

const Dashboard = ({ children }) => {
  const menuItems = compact(map(routes, 'menu'))
  const authUser = useContext(AuthUserContext)
  const history = useHistory()
  const { lang, changeLanguage } = useContext(LanguageContext)

  const [displayName, setDisplayName] = useState('')
  const { url: profilePicUrl } = useProfilePic()

  useEffect(() => {
    if (authUser) {
      const docRef = firebase.firestore().doc(`freelancer_profiles/${authUser?.uid}`)
      return docRef.onSnapshot(doc => {
        if (doc.exists) {
          setDisplayName(`${doc.data()?.generalInfo?.firstName} ${doc.data()?.generalInfo?.lastName}`)
        }
      })
    }
  }, [authUser])

  return (
    <Layouts.Dashboard
      navMenu={
        menuItems.map(({ title, href, icon }) => (
          <Item key={href} title={title} href={href} icon={icon} />
        ))
      }
      profileProps={{
        profileHref: '/profile',
        displayName: displayName || '',
        photoURL: profilePicUrl,
        email: authUser?.email || ''
      }}
      langProps={{
        onChange: changeLanguage,
        lang
      }}
      onSignOut={async () => {
        await firebase.auth().signOut()
        history.replace('/sign-in')
      }}
    >
      {children}
    </Layouts.Dashboard>
  )
}

export default Dashboard
