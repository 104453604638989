import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {}
}))

const ProfileBio = ({ className, values = '', onChange, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation('freelancer')

  const handleChange = event => {
    event.preventDefault()
    onChange(event.target.value)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
      >
        <CardHeader
          title={t('profile.biography.title')}
          subheader={t('profile.biography.subheader')}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                multiline
                placeholder={t('profile.biography.placeholder')}
                onChange={handleChange}
                value={values}
                rows={8}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  )
}

ProfileBio.propTypes = {
  className: PropTypes.string,
  values: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ProfileBio
