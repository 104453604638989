import {
  ProjectsCard,
  EarningsCard,
  // InvoicesCard,
  NotificationCard,
  UpcomingTasksCard,
  TasksCard
} from '@leanlancer/ui/dist/modules/Dashboard'
import { Grid, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  fullHeight: {
    height: '100%'
  },
  root: {
    padding: theme.spacing(4),
    minHeight: '100%'
  }
}))

const Home = () => {
  const classes = useStyles()
  const theme = useTheme()
  const [tasks, setTasks] = useState({ completed: 0, pending: 0, loading: true })
  const [projects, setProjects] = useState({ loading: true, completed: 0, inProgress: 0 })
  const [upcomingTasks, setUpcomingTasks] = useState({ loading: true, tasks: [] })

  // UpcomingTasksCard
  useEffect(() => {
    return firebase.firestore().collection('project_tasks')
      .where('status', '==', 'pending')
      .limit(5)
      .onSnapshot(snap => {
        const tasks = snap.docs.map(d => ({ id: d.id, ...d.data() }))
        setUpcomingTasks({ loading: false, tasks })
      })
  }, [])

  // TasksCard
  useEffect(() => {
    return firebase.firestore().collection('project_tasks')
      .where('status', 'in', ['pending', 'completed'])
      .onSnapshot(snap => {
        setTasks({
          loading: false,
          pending: snap.docs.filter(s => s.data().status === 'pending').length,
          completed: snap.docs.filter(s => s.data().status === 'completed').length
        })
      })
  }, [])

  // ProjectsCard
  useEffect(() => {
    return firebase.firestore().collection('projects')
      .onSnapshot(snap => {
        setProjects({
          loading: false,
          inProgress: snap.docs.filter(d => d.data().status === 'in_progress').length,
          completed: snap.docs.filter(d => d.data().status === 'completed').length
        })
      })
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid item xs={12} lg={9}>
          <NotificationCard className={classes.fullHeight} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <EarningsCard
            className={classes.fullHeight}
            style={{ backgroundColor: theme.palette.primary.dark }}
            value={0}
          />
        </Grid>
        {/* <Grid item>
          <InvoicesCard
            className={classes.fullHeight}
            style={{ backgroundColor: '#E19E20' }}
            paid={0}
            overdue={0}
          />
        </Grid> */}
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <TasksCard
            className={classes.fullHeight}
            style={{ backgroundColor: '#E19E20' }}
            // loading={tasks.loading}
            completed={tasks.completed}
            pending={tasks.pending}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <ProjectsCard
            className={classes.fullHeight}
            style={{ backgroundColor: '#E19E20' }}
            completed={projects.completed}
            inProgress={projects.inProgress}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UpcomingTasksCard
            style={{ height: '100%' }}
            loading={upcomingTasks.loading}
            tasks={upcomingTasks.tasks}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
