import { useProfilePic } from '@leanlancer/common'
import { Flex } from '@leanlancer/ui'
import {
  Avatar,
  IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import AuthUserContext from '../../contexts/authUser.context'

const INITAL_USER = {
  firstName: '',
  lastName: '',
  city: '',
  country: ''
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  avatar: {
    height: 110,
    width: 110,
    [theme.breakpoints.up('sm')]: {
      height: 140,
      width: 140
    },
    [theme.breakpoints.up('md')]: {
      height: 170,
      width: 170
    },
    flexShrink: 0,
    flexGrow: 0
  },
  photoActions: {
    minWidth: 140
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  fileUploadInput: {
    display: 'none'
  }
}))

const ProfilePicture = ({ user = INITAL_USER, ...props }) => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const { url: profileUrl, updatePic, deletePic } = useProfilePic()

  const uploadFile = async (files) => {
    if (!authUser) {
      // TODO: handle correctly
      return console.error('Cannot upload photos without being logged in')
    }
    updatePic(files[0])
  }

  const removeProfilePicture = async () => {
    deletePic()
  }

  return (
    <Flex dir='ttb' center>
      {/* <img src={profileUrl} alt='profile' width='100%' /> */}
      <Avatar
        className={classes.avatar}
        src={profileUrl}
      />
      <Flex justify='between' className={classes.photoActions}>
        <IconButton aria-label='delete' onClick={removeProfilePicture}>
          <DeleteIcon />
        </IconButton>
        <input
          accept='image/*'
          className={classes.fileUploadInput}
          id='upload-profile-picture'
          type='file'
          onChange={(e) => uploadFile(e.target.files)}
        />
        <label htmlFor='upload-profile-picture'>
          <IconButton color='primary' aria-label='upload picture' component='span'>
            <EditIcon />
          </IconButton>
        </label>
      </Flex>
    </Flex>
  )
}

ProfilePicture.propTypes = {
  className: PropTypes.string
}

export default ProfilePicture
