import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid } from '@material-ui/core'
// import Notifications from './Notifications'
import AuthenticationSettings from './AuthenticationSettings'
import { CommunicationSettings } from '@leanlancer/ui'
import { useTranslation } from 'react-i18next'
import { useSnackbar, useDocument } from '@leanlancer/common'
import AuthUserContext from '../../contexts/authUser.context'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  saveButton: {
    float: 'right'
  }
}))

const Settings = () => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const [userSnap, userData] = useDocument(firebase.firestore().doc(`/users/${authUser.uid}`))
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const [state, setState] = useState({
    preferredLanguage: userData?.preferredLanguage || ''
  })

  useEffect(() => {
    if (userSnap) {
      return userSnap.ref.onSnapshot((snap) => {
        if (snap.exists) {
          setState({
            preferredLanguage: userSnap.data().preferredLanguage || ''
          })
        }
      })
    }
  }, [userSnap])

  const handleSave = async (e) => {
    await userSnap.ref.set(state, { merge: true })
    snackbar({
      type: 'open',
      message: t('common:alerts.saved')
    })
  }

  const handleChange = (key) => (e, val) => {
    setState({ ...state, [key]: val })
  }

  return (
    <div className={classes.root}>

      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Button color='primary' variant='outlined' className={classes.saveButton} onClick={handleSave}>
            {t('common:inputs.save')}
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <CommunicationSettings
            onPreferredLanguageChange={handleChange('preferredLanguage')}
            preferredLanguage={state.preferredLanguage}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <AuthenticationSettings />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Button color='primary' variant='outlined' className={classes.saveButton} onClick={handleSave}>
            {t('common:inputs.save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default Settings
