import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import AuthUserContext from '../../contexts/authUser.context'

const RouteWithLayout = ({ layout: Layout, component: Component, reserved = false, ...rest }) => {
  const authUser = useContext(AuthUserContext)

  if (reserved && !authUser) {
    return <Redirect to='/sign-in' />
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default RouteWithLayout
