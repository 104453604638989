const resources = {
  en: {
    freelancer: {
      home: {
        welcome: 'Welcome to LeanLancer!',
        greeting: `Nice that you are participating in our Early Access Program!
        As you will soon notice, most of the features are not yet available such as projects or invoices.
        However you can already create a profile and we will contact you as soon as we have a job for you - so hopefully very soon!`,
        next: 'So, what happens now?',
        step1Title: '1. Fill out profile',
        step1Content: 'Once we recieve your basic information and CV you will be added to our talent pool. It is important to include an up to date CV so we can easily find jobs that will match your talents and abilities!',
        step2Title: '2. We start the search',
        step2Content: 'Once we have a job that is relevant to you, we will be in touch via email and/or phone to inform you about the role and the responsibilities that go along with it. If both parties agree on the terms than we will send you a contract to start!',
        step3Title: '3. Sign the contract',
        step3Content: 'Our contracts are straight forward and contain clear information on what will be expected of you. If you have any reservations we will work with you to make sure that everything is ironed out and we can come to an agreement together.',
        step4Title: '4. Get the job done',
        step4Content: 'Once everything is in place, we hand over the work to you. At the completion we will go over it and make sure that everything specified in our agreement has been fulfilled. If you need any help during this step, contact us so we can help you. We want to work with you to make sure every party leaves satisfied!',
        step5Title: '5. Payment',
        step5Content: 'At the end of each job we release your payment immediately. The money is held from the moment you sign the contract so you can expect to get paid in full, fast.'
      },
      signIn: {
        quote: '“The person born with a talent they are meant to use will find their greatest happiness in using it.”',
        quoteAuthor: 'Johann Wolfgang von Goethe',
        quoteProfession: 'German Poet',
        title: 'Sign In',
        subline: 'Sign in with social media',
        loginFacebook: 'Login with Facebook',
        loginGoogle: 'Login with Google',
        loginEmail: 'or login with email address',
        submit: 'Sign in now',
        noAccount: "Don't have an account?",
        signUp: 'Sign up',
        emailLabel: 'Email address',
        passwordLabel: 'Password',
        forgotPassword: 'Forgot Password?',
        passwordResetSent: 'An email has been sent with password reset instructions',
        passwordResetEmailMissing: 'Enter you email address to reset password'
      },
      signUp: {
        title: 'Create new account',
        subline: 'Sign up with social media',
        firstNameLabel: 'First name',
        lastNameLabel: 'Last name',
        emailLabel: 'Email address',
        passwordLabel: 'Password',
        tcRead: 'I have read the',
        tc: 'Terms and Conditions',
        tcAfter: '',
        submit: 'Sign up now',
        hasAccount: 'Have an account?',
        signIn: 'Sign in',
        google: 'Sign up with Google',
        facebook: 'Sign up with Facebook',
        signUpEmail: 'or sign up using email'
      },
      profile: {
        save: 'Save',
        savedAlert: 'Saved!',
        general: { title: 'General Information' },
        skills: {
          title: 'Skills',
          subheader: 'Create a list of keywords that outline your relevant skills',
          placeholder: 'Enter your skill here (e.g ReactJS or MongoDB)'
        },
        biography: {
          title: 'Biography',
          subheader: 'Write a little about yourself',
          placeholder: 'Write some information about your background and personality'
        },
        languages: {
          title: 'Languages',
          subheader: 'Languages you can work or communicate in',
          add: 'Add Another Language',
          proficieny: 'Proficieny',
          native: 'Native',
          fluent: 'Fluent',
          proficient: 'Proficient',
          basic: 'Basic'
        },
        cv: {
          title: 'CV Upload',
          subheader: 'Upload you CV here for review by our staff'
        }
      },
      settings: {
        authentication: {
          title: 'Authentication',
          subtitle: 'From here you can add and edit login methods',
          passwordReset: 'Reset Password',
          passwordMismatch: 'These passwords do not match',
          linkGoogle: 'Link Google Account',
          googleLinked: 'Google acccount linked',
          googleLinkedSuccess: 'Google acccount successfully linked',
          linkFacebook: 'Link Facebook Account',
          facebookLinked: 'Facebook account linked',
          facebookLinkedSuccess: 'Facebook account successfully linked',
          reauthDialogTitle: 'Sign in again required for security reasons',
          reauthWithPassword: 'Sign in using password',
          reauthSuccess: 'Successfully signed in again. You may now link accounts',
          setPassword: 'Set a password for your account',
          repeatPassword: 'Repeat password',
          setPasswordSubmit: 'Set Password',
          resetPasswordSubmit: 'Reset Password'
        }
      },
      projects: {
        projectMembers: 'Project Members',
        skills: 'Skills'
      }
    }
  },
  de: {
    freelancer: {
      home: {
        welcome: 'Willkommen bei LeanLancer!',
        greeting: `Schön, dass Du an unserem Early Access Programm teilnimmst!!
        Wie Du bald feststellen wirst, sind die meisten Funktionen noch nicht verfügbar, wie z.B. Projekte oder Rechnungen.
        Du kannst jedoch bereits ein Profil erstellen, und wir werden uns mit Dir in Verbindung setzen, sobald wir einen Job für Dich haben - also hoffentlich sehr bald!`,
        next: 'Was passiert jetzt?',
        step1Title: '1. Profil ausfüllen',
        step1Content: 'Sobald wir Deine grundlegenden Informationen und Deinen Lebenslauf erhalten haben, wirst Du in unseren Talentpool aufgenommen. Es ist wichtig, einen aktuellen Lebenslauf beizufügen, damit wir leicht Jobs finden können, die Deinen Talenten und Fähigkeiten entsprechen!',
        step2Title: '2. Wir beginnen die Suche',
        step2Content: 'Sobald wir eine Stelle haben, die für Dich relevant ist, werden wir uns per E-Mail und/oder Telefon mit Dir in Verbindung setzen, um Dich über die Rolle und die damit verbundenen Verantwortlichkeiten zu informieren. Wenn sich beide Parteien über die Bedingungen einig sind, schicken wir Dir einen Vertrag zu!',
        step3Title: '3. Unterschreibe den Vertrag',
        step3Content: 'Unsere Verträge sind unkompliziert und enthalten klare Informationen darüber, was von Dir erwartet wird. Wenn Du irgendwelche Vorbehalte hast, werden wir mit Dir zusammenarbeiten, um sicherzustellen, dass alles geklärt ist und wir gemeinsam zu einer Einigung kommen können.',
        step4Title: '4. Schließe den Job ab',
        step4Content: 'Sobald alles an Ort und Stelle ist, übergeben wir die Arbeit an Dich. Nach der Fertigstellung werden wir das Ergebnis überprüfen und sicherstellen, dass alles, was in unserer Vereinbarung festgelegt wurde, erfüllt ist. Wenn Du bei diesem Schritt Hilfe benötigst, kontaktiere uns, damit wir Dir helfen können. Wir möchten mit Dir zusammenarbeiten, um sicherzustellen, dass alle Parteien zufrieden sind!',
        step5Title: '5. Bezahlung',
        step5Content: 'Am Ende jedes Auftrags geben wir Deine Zahlung sofort frei. Das Geld wird von dem Moment an einbehalten, in dem Sie den Vertrag unterschreiben, so dass Sie erwarten können, schnell und vollständig bezahlt zu werden.'
      },
      signIn: {
        quote: '„Jemand, der mit einem Talent geboren wurde, wird seine größte Glückseligkeit erleben, wenn er dieses Talent benutzt. "',
        quoteAuthor: 'Johann Wolfgang von Goethe',
        quoteProfession: 'Deutcher Dichter',
        title: 'Anmelden',
        subline: 'mit Social-Media-Account',
        loginFacebook: 'Anmelden mit Facebook',
        loginGoogle: 'Anmelden mit Google',
        loginEmail: 'oder mit E-Mail-Adresse',
        submit: 'Jetzt anmelden',
        noAccount: 'Noch kein Konto?',
        signUp: 'Jetzt registrieren.',
        emailLabel: 'E-Mail-Adresse',
        passwordLabel: 'Passwort',
        forgotPassword: 'Passwort vorgessen?',
        passwordResetSent: 'Es wurde eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet.',
        passwordResetEmailMissing: 'Gib Deine E-Mail-Adresse ein, um das Passwort zurückzusetzen.'
      },
      signUp: {
        title: 'Neues Konto erstellen',
        subline: 'Mit Social Media registrieren',
        firstNameLabel: 'Vorname',
        lastNameLabel: 'Nachname',
        emailLabel: 'E-Mail-Adresse',
        passwordLabel: 'Passwort',
        tcRead: 'Ich habe die',
        tc: 'Nutzungsbedingungen',
        tcAfter: 'gelesen',
        submit: 'Jetzt registrieren',
        hasAccount: 'Hast du ein Konto?',
        signIn: 'Anmelden',
        google: 'Mit Google registrieren',
        facebook: 'Mit Facebook registrieren',
        signUpEmail: 'oder registriere Dich per E-Mail'
      },
      profile: {
        save: 'Speichern',
        savedAlert: 'Gespeichert!',
        general: { title: 'Allgemeine Informationen' },
        skills: {
          title: 'Fähigkeiten',
          subheader: 'Erstelle eine Liste mit Stichwörtern, die Deine relevanten Fähigkeiten beschreiben',
          placeholder: 'Gebe Deine Fähigkeiten ein (bspw. ReactJS oder MongoDB)'
        },
        biography: {
          title: 'Biografie',
          subheader: 'Erzähle etwas über Dich',
          placeholder: 'Schreibe ein paar Zeilen über Deinen Hintergrund und Deine Persönlichkeit'
        },
        languages: {
          title: 'Sprachen',
          subheader: 'Sprachen, in denen du arbeiten oder kommunizieren kannst',
          add: 'Weitere Sprache hinzufügen',
          proficieny: 'Level',
          native: 'Muttersprachler',
          fluent: 'Fließend',
          proficient: 'Gute Kenntnisse',
          basic: 'Grundkenntnisse'
        },
        cv: {
          title: 'Lebenslauf hochladen',
          subheader: 'Lade Deinen Lebenslauf zur Überprüfung, damit wir Deine Fähigkeiten validieren können'
        }
      },
      settings: {
        authentication: {
          title: 'Authentifizierung',
          subtitle: 'Von hier aus kannst Du Anmeldemethoden hinzufügen und bearbeiten',
          passwordMismatch: 'Das eingegebene Passwort stimmt nicht überein.',
          linkGoogle: 'Google-Konto verknüpfen',
          googleLinked: 'Google-Konto verknüpft',
          googleLinkedSuccess: 'Google-Konto erfolgreich verknüpft',
          linkFacebook: 'Facebook-Konto verknüpfen',
          facebookLinked: 'Facebook-Konto verknüpft',
          facebookLinkedSuccess: 'Facebook-Konto erfolgreich verknüpft',
          reauthDialogTitle: 'Melde Dich aus Sicherheitsgründen erneut an',
          reauthWithPassword: 'Mit Passwort anmelden',
          reauthSuccess: 'Wieder erfolgreich angemeldet. Du kannst jetzt Konten verknüpfen',
          setPassword: 'Leg ein Passwort für Dein Konto fest',
          repeatPassword: 'Wiederhole das Passwort',
          setPasswordSubmit: 'Passwort festlegen',
          resetPasswordSubmit: 'Passwort zurücksetzen'
        }
      },
      projects: {
        projectMembers: 'Projekt-Mitglieder',
        skills: 'Fertigkeiten'
      }
    }
  }
}
export default resources
