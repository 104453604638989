import i18n from '../i18n'

export const required = { required: i18n.t('common:errors.required') }
export const email = {
  ...required,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: i18n.t('common:errors.emailInvalid')
  }
}
