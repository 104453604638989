import { ProjectDetail, TaskTable } from '@leanlancer/ui/dist/modules/Projects'
import { Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  tabLink: {
    color: 'inherit'
  }
}))

export default ({ id, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const match = useRouteMatch()
  const { pathname } = useLocation()

  const [project, setProject] = useState({})
  const [milestones, setMilestones] = useState([])
  const [tasks, setTasks] = useState([])
  const [taskTableData, setTaskTableData] = useState({})

  useEffect(() => {
    if (!id) {
      throw new Error('No project id provided')
    }

    return firebase.firestore().doc(`projects/${id}`)
      .onSnapshot(snap => {
        if (snap) {
          setProject(snap.data())
        } else {
          throw new Error(`Project with id ${id} not found`)
        }
      })
  }, [id])

  useEffect(() => {
    return firebase.firestore().collection('project_milestones').where('projectId', '==', id)
      .onSnapshot(snap => {
        if (snap.size) {
          setMilestones(snap.docs.map(d => ({ ...d.data(), id: d.id })))
        }
      })
  }, [id])

  useEffect(() => {
    return firebase.firestore().collection('project_tasks').where('projectId', '==', id)
      .onSnapshot(snap => {
        if (snap.size) {
          setTasks(snap.docs.map(d => ({ ...d.data(), id: d.id })))
        }
      })
  }, [id])

  useEffect(() => {
    const milestoneData = milestones?.map(milestone => ({ ...milestone, type: 'milestone' }))
    const tasksData = tasks?.map(task => ({ ...task, parentId: task.milestoneId, type: 'task' }))
    const taskTableData = [...milestoneData, ...tasksData]
    setTaskTableData(taskTableData)
  }, [project, tasks, milestones])

  return (
    <>
      <Tabs value={pathname}>
        <Tab
          label={t('projects.generalInformation')}
          component={Link}
          value={`${match.url}`}
          to={`${match.url}`}
          className={classes.tabLink}
        />
        <Tab
          label={t('projects.tasks')}
          component={Link}
          value={`${match.url}/tasks`}
          to={`${match.url}/tasks`}
          className={classes.tabLink}
        />
      </Tabs>

      <div className={classes.container}>
        <Switch>

          <Route path={`${match.path}`} exact>
            <ProjectDetail
              title={project.title}
              description={project.description}
              amount={`${project.totalCost}`}
              status={project.status}
              dueDate={project.endDate}
              {...props}
            />
          </Route>
          <Route path={`${match.path}/tasks`} exact>
            <TaskTable data={taskTableData} />
          </Route>
        </Switch>
      </div>
    </>
  )
}
