import React, { useContext } from 'react'
import { Layouts } from '@leanlancer/ui'
import LanguageContext from '../contexts/langauge.context'

const Minimal = ({ children }) => {
  const { lang, changeLanguage } = useContext(LanguageContext)

  return (
    <Layouts.Minimal
      topBarProps={{
        onLangChange: changeLanguage,
        lang
      }}
    >
      {children}
    </Layouts.Minimal>
  )
}

export default Minimal
