import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton
} from '@material-ui/core'
import { zipObject } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PROFILE_LANGUAGES } from '@leanlancer/common'

const SORTED_PROFILE_LAGUAGES = PROFILE_LANGUAGES.sort((a, b) => {
  const nameA = a.label.toUpperCase() // ignore upper and lowercase
  const nameB = b.label.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
})

const useStyles = makeStyles(theme => ({
  root: {}
}))

const INPUT_KEYS = ['language', 'proficiency']

const LanguageSection = ({ values, onRemove, onChange }) => {
  const { t } = useTranslation(['common', 'freelancer'])

  values = zipObject(INPUT_KEYS, INPUT_KEYS.map(k => values[k] ? values[k] : ''))
  return (
    <Box my={2}>
      <Grid container>
        <Grid item xs={11}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={6}
            >
              <FormControl fullWidth variant='outlined'>
                <InputLabel id='language'>{t('inputs.language')}</InputLabel>
                <Select
                  label={t('inputs.language')}
                  labelId='language'
                  placeholder={t('inputs.language')}
                  name='language'
                  onChange={onChange}
                  value={values.language}
                >
                  {SORTED_PROFILE_LAGUAGES.map(({ id }) => {
                    return (
                      <MenuItem key={id} value={id}>{t(`langs.${id}`)}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <FormControl fullWidth variant='outlined'>
                <InputLabel id='proficieny'>{t('freelancer:profile.languages.proficieny')}</InputLabel>
                <Select
                  label={t('freelancer:profile.languages.proficieny')}
                  labelId='proficieny'
                  placeholder={t('freelancer:profile.languages.proficieny')}
                  name='proficiency'
                  onChange={onChange}
                  value={values.proficiency}
                >
                  <MenuItem value='native'>{t('freelancer:profile.languages.native')}</MenuItem>
                  <MenuItem value='fluent'>{t('freelancer:profile.languages.fluent')}</MenuItem>
                  <MenuItem value='proficient'>{t('freelancer:profile.languages.proficient')}</MenuItem>
                  <MenuItem value='basic'>{t('freelancer:profile.languages.basic')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <IconButton onClick={onRemove}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const ProfileLanguages = ({ className, values = [], onChange, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation(['freelancer', 'common'])

  const handleAddSection = () => {
    onChange([...values, {}])
  }

  const handleRemoveSection = (index) => {
    onChange([...values.slice(0, index), ...values.slice(index + 1, values.length)])
  }

  const handleSectionChange = (e, index) => {
    onChange(values.map((sec, i) => index === i ? ({ ...sec, [e.target.name]: e.target.value }) : sec))
  }

  return (
    <Card
      {...props}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
      >
        <CardHeader
          title={t('profile.languages.title')}
          subheader={t('profile.languages.subheader')}
        />
        <Divider />
        <CardContent>
          {values.map((s, i) => (
            <LanguageSection
              key={i}
              values={s}
              onChange={(e) => handleSectionChange(e, i)}
              onRemove={() => handleRemoveSection(i)}
            />
          ))}
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Box display='flex' justifyContent='flex-end' mt={3}>
                <Button variant='outlined' onClick={handleAddSection}>
                  {t('profile.languages.add')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  )
}

ProfileLanguages.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ProfileLanguages
