import AccountBoxIcon from '@material-ui/icons/AccountBox'
import HomeIcon from '@material-ui/icons/Home'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentIcon from '@material-ui/icons/Assignment'
import React from 'react'
import { RouteWithLayout } from '../components'
import { Dashboard as DashboardLayout, Minimal as MinimalLayout } from '../layouts'
import {
  Home,
  NotFound,
  Profile,
  Projects,
  Settings,
  SignIn,
  SignUp
} from '../views'

export default {
  HOME: {
    menu: {
      title: 'home',
      href: '/',
      icon: <HomeIcon />
    },
    render: (
      <RouteWithLayout
        key='HOME'
        reserved
        component={Home}
        exact
        layout={DashboardLayout}
        path='/'
      />
    )
  },
  // DASHBOARD: {
  //   menu: {
  //     title: 'Dashboard',
  //     href: '/dashboard',
  //     icon: <DashboardIcon />
  //   },
  //   render: (
  //     <RouteWithLayout
  //       key='DASHBOARD'
  //       reserved
  //       component={DashboardView}
  //       exact
  //       layout={DashboardLayout}
  //       path='/dashboard'
  //     />
  //   )
  // },
  PROJECTS: {
    menu: {
      title: 'projects',
      href: '/projects',
      icon: <AssignmentIcon />
    },
    render: (
      <RouteWithLayout
        key='PROJECTS'
        reserved
        exact
        component={Projects}
        layout={DashboardLayout}
        path='/projects'
      />
    )
  },
  PROJECTDETAIL: {
    render: (
      <RouteWithLayout
        key='PROJECTDETAIL'
        reserved
        component={Projects}
        layout={DashboardLayout}
        path='/projects/:id'
      />
    )
  },
  // JOB_OFFERS: {
  //   menu: {
  //     title: 'Job Offers',
  //     href: '/offers',
  //     icon: <WorkIcon />
  //   },
  //   render: (
  //     <RouteWithLayout
  //       key='JOB_OFFERS'
  //       reserved
  //       component={ProjectView}
  //       exact
  //       layout={DashboardLayout}
  //       path='/offers'
  //     />
  //   )
  // },
  // INVOICES: {
  //   menu: {
  //     title: 'Invoices',
  //     href: '/invoices',
  //     icon: <DescriptionIcon />
  //   },
  //   render: (
  //     <RouteWithLayout
  //       key='INVOICES'
  //       reserved
  //       component={InvoicesView}
  //       exact
  //       layout={DashboardLayout}
  //       path='/invoices'
  //     />
  //   )
  // },
  // TYPOGRAPHY: {
  //   render: (
  //     <RouteWithLayout
  //       key='TYPOGRAPHY'
  //       component={TypographyView}
  //       exact
  //       layout={DashboardLayout}
  //       path='/typography'
  //     />
  //   )
  // },
  // ICONS: {
  //   render: (
  //     <RouteWithLayout
  //       key='ICONS'
  //       component={IconsView}
  //       exact
  //       layout={DashboardLayout}
  //       path='/icons'
  //     />
  //   )
  // },
  PROFILE: {
    menu: {
      title: 'profile',
      href: '/profile',
      icon: <AccountBoxIcon />
    },
    render: (
      <RouteWithLayout
        key='PROFILE'
        reserved
        component={Profile}
        exact
        layout={DashboardLayout}
        path='/profile'
      />
    )
  },
  SETTINGS: {
    menu: {
      title: 'settings',
      href: '/settings',
      icon: <SettingsIcon />
    },
    render: (
      <RouteWithLayout
        key='SETTINGS'
        reserved
        component={Settings}
        exact
        layout={DashboardLayout}
        path='/settings'
      />
    )
  },
  SIGN_UP: {
    render: (
      <RouteWithLayout
        key='SIGN_UP'
        component={SignUp}
        exact
        layout={MinimalLayout}
        path='/sign-up'
      />
    )
  },
  SIGN_IN: {
    render: (
      <RouteWithLayout
        key='SIGN_IN'
        component={SignIn}
        exact
        layout={MinimalLayout}
        path='/sign-in'
      />
    )
  },
  NOT_FOUND: {
    render: (
      <RouteWithLayout
        key='NOT_FOUND'
        component={NotFound}
        exact
        layout={MinimalLayout}
        path='/not-found'
      />
    )
  }
}
