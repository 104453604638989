import { Card, CardHeader, Divider, Grid, Hidden, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import { Flex } from '@leanlancer/ui'
import DownloadIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined'
import AuthUserContext from '../../contexts/authUser.context'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {},
  dropzoneContainer: {
    margin: theme.spacing(3),
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: theme.palette.grey
  },
  dropzone: {
    padding: theme.spacing(4)
  },
  fileTable: {
    padding: theme.spacing(2)
  },
  tableEntry: {
    height: '100%',
    padding: theme.spacing(1)
  },
  fileProgress: {
    margin: theme.spacing(0, 1)
  }
}))

const FileRow = ({ fileName, progress, updateTime = '', deleteFile, openFile }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleEditClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = () => {
    openFile()
    handleEditClose()
  }

  const handleDelete = () => {
    deleteFile()
    handleEditClose()
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleEditClose}
      >
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <DownloadIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Download' />
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
      <Grid item xs={9} sm={6}>
        <Flex alignItems='start' justify='center' dir='ttb' className={classes.tableEntry}>
          <Typography variant='body1'>{fileName}</Typography>
          <Hidden smUp>
            <Typography variant='caption'>{updateTime}</Typography>
          </Hidden>
        </Flex>
      </Grid>
      <Hidden smUp>
        <Grid item xs={3}>
          <Flex justify='end'>
            <IconButton onClick={handleEditClick}><EditIcon /></IconButton>
          </Flex>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid item sm={4}>
          <Flex alignItems='center' className={classes.tableEntry}>
            <Typography variant='overline'>{updateTime}</Typography>
          </Flex>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Grid item sm={2} className={classes.tableEntry}>
          <Flex justify='around'>
            <IconButton onClick={handleDownload}><DownloadIcon /></IconButton>
            <IconButton onClick={handleDelete}><DeleteIcon /></IconButton>
          </Flex>
        </Grid>
      </Hidden>
      {progress < 100 && (
        <Grid item xs={12}>
          <LinearProgress variant='determinate' value={progress} className={classes.fileProgress} />
        </Grid>
      )}
    </>
  )
}

const FileTable = ({ fileList }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2} className={classes.fileTable}>
      {fileList && fileList.map((storageFile) => {
        return (
          <FileRow
            key={storageFile.fileName}
            {...storageFile}
          />
        )
      })}
    </Grid>
  )
}

const StorageFile = ({
  dispatch,
  fileRef,
  fileName,
  ...props
}) => {
  return {
    ...props,
    fileName,
    fileRef,
    deleteFile: async () => {
      await fileRef.delete()
      dispatch({ type: 'remove', fileName })
    },
    openFile: async () => {
      const url = await fileRef.getDownloadURL()
      window.open(url, '_blank')
    }
  }
}

const fileListReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      // TODO: check that the file is not already in the list
      return [...state, action.file]
    case 'remove':
      return state.filter(f => f.fileName !== action.fileName)
    case 'setProgress':
      return state.map(f => f.fileName === action.fileName ? { ...f, progress: action.progress } : f)
    case 'setUpdateTime':
      return state.map(f => f.fileName === action.fileName ? { ...f, updateTime: action.updateTime } : f)
    case 'reset':
      return []
    default:
      throw new Error()
  }
}

const ProfileUploadCv = ({ className, ...props }) => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const [fileList, dispatch] = useReducer(fileListReducer, [])
  const { t } = useTranslation('freelancer')

  useEffect(() => {
    const cvDirRef = firebase.storage().ref().child(`cvs/${authUser.uid}`)
      ; (async () => {
      if (!authUser?.uid) {
        return console.error('Auth user not found')
      }
      const { items } = await cvDirRef.listAll()
      items.forEach(i => {
        const file = StorageFile({
          dispatch,
          fileName: i.name,
          fileRef: cvDirRef.child(i.name),
          progress: 100
        })
        dispatch({ type: 'add', file })
      })
    })()
  }, [authUser])

  const onDrop = useCallback(acceptedFiles => {
    if (!authUser?.uid) {
      return console.error('Could not load auth user')
    }

    const cvDirRef = firebase.storage().ref().child(`cvs/${authUser.uid}`)

    acceptedFiles.forEach(async f => {
      const fileRef = cvDirRef.child(f.name)
      const fileName = f.name
      const file = StorageFile({
        dispatch,
        fileName,
        fileRef,
        progress: 0
      })
      dispatch({ type: 'add', file })
      const task = fileRef.put(f)

      task.on('state_changed', function (snapshot) {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        dispatch({ type: 'setProgress', fileName, progress })
      }, function (error) {
        console.error('Error during file upload')
        throw error // TODO: handle
      }, function () {
        dispatch({ type: 'setProgress', fileName, progress: 100 })
      })
    })
  }, [authUser])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <Card
      {...props}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={t('profile.cv.title')}
        subheader={t('profile.cv.subheader')}
      />
      <Divider />
      <FileTable fileList={fileList} />
      <div className={classes.dropzoneContainer}>
        <Flex {...getRootProps()} center className={classes.dropzone}>
          <input {...getInputProps()} />
          {isDragActive
            ? <CloudUploadIcon fontSize='large' color='primary' />
            : <CloudUploadIcon fontSize='large' />}
        </Flex>
      </div>
    </Card>
  )
}

export default ProfileUploadCv
